import React, { useState } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { IntroGrid, Heading } from "components";
import { motion } from "framer-motion";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import cn from "classnames";
import { AnimationOnScroll } from "react-animation-on-scroll";
import LazyLoad from "react-lazyload";
import { sections } from "./Sections.module.scss";
import "animate.css/animate.min.css";
import phoneVideo from "../../videos/video-640.mp4";

export function Sections() {
  const styles =
    "lg:flex items-center mx-auto min-h-screen relative py-8 lg:py-0";

  return (
    <main>
      <section
        id="technology"
        className={cn(sections, styles, "container max-w-screen-lg")}
      >
        <div className="lg:absolute left-0 right-0 mx-auto mb-10 lg:my-0 -z-1 w-full max-w-screen-sm h-auto">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <StaticImage
              src="../../images/technology.jpg"
              alt="Technology"
              placeholder="blurred"
              layout="fullWidth"
            />
          </AnimationOnScroll>
        </div>
        <article className="relative max-w-none lg:max-w-sm">
          <AnimationOnScroll animateIn="animate__fadeInDown">
            <h1 className="text-right mb-8">
              Technology
              <br /> Driven by
              <br /> Coffee
            </h1>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInLeft">
            <p>
              In addition to printing packages, we can now print your own mobile
              application (app) the Savor Brands way. Tailored for Specialty
              Coffee, the app complements your packaging to help you deliver
              origin and how to brew stories using its built-in Augmented
              Reality (AR) function. Customers can order, pay, and have their
              purchases ready for pick up or shipped.
            </p>
          </AnimationOnScroll>
        </article>
        <div className="lg:absolute bottom-40 right-0 w-60">
          <AnimationOnScroll animateIn="animate__fadeInRight">
            <p className="text-black">
              We help you place custom branded TECHNOLOGY DRIVEN BY COFFEE in
              the hands of your customers.
            </p>
          </AnimationOnScroll>
        </div>
        <div className="lg:absolute bottom-10 w-full">
          <AnimationOnScroll offset={-300} animateIn="animate__fadeInDown">
            <p className="text-center text-black text-lg">
              Built-in Augmented Reality (AR) function.
            </p>
          </AnimationOnScroll>
        </div>
      </section>
      <section id="ar" className={cn(sections, styles, "bg-gray-100")}>
        <article className="container lg:flex align-middle justify-start max-w-screen-lg mx-auto relative">
          <div className="relative z-10 max-w-none lg:max-w-md">
            <AnimationOnScroll animateIn="animate__fadeIn">
              <h2 className="mb-8">
                Story
                <br /> Telling
                <br /> Made Easy
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInUp">
              <p>
                Powered with image recognition technology, your app uses your
                phone’s camera to identify an image or code on your package to
                activate an Augmented Reality (AR) experience, creating a
                digital filter over the physical world. Effects surround your
                package and customers are free to choose their experience with a
                single tap. With each button is an experience curated by you.
                Take your audience to origin, or into your lab for a how to brew
                experience. Share what you believe matters. Experiences are
                limitless and now your ability to share is effortless.
              </p>
            </AnimationOnScroll>
          </div>
          <div className="lg:absolute right-0 mx-auto -z-1 w-full max-w-screen-sm h-auto">
            <LazyLoad height={640} offset={640}>
              <video
                width="640"
                height="640"
                controls={false}
                autoPlay={true}
                muted={true}
                playsInline={true}
                loop={true}
                allow="autoplay; picture-in-picture"
                className="aspect-square"
              >
                <source src={phoneVideo} type="video/mp4" />
              </video>
            </LazyLoad>
          </div>
        </article>
      </section>
      <section
        id="payments"
        className={cn(sections, styles, "container max-w-screen-lg")}
      >
        <article className="lg:flex items-center justify-center max-w-screen-lg mx-auto">
          <div className="w-36 mx-auto lg:mx-0 lg:w-full mb-10 lg:mb-0">
            <AnimationOnScroll animateIn="animate__fadeIn">
              <StaticImage
                src="../../images/phones.png"
                alt="Phones"
                placeholder="blurred"
                className="lg:max-w-sm"
              />
            </AnimationOnScroll>
          </div>
          <div className="text-right max-w-none lg:max-w-md">
            <AnimationOnScroll animateIn="animate__fadeInDown">
              <h2 className="mb-8">
                Transactions
                <br /> Made Simple
              </h2>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInUp">
              <p>
                Your app also keeps things basic. If customers just want more
                coffee, they can browse your menu, purchase, and choose if they
                want their order to be picked up or shipped. Transactions are
                processed securely through our partner, US Bank’s Elavon.
              </p>
            </AnimationOnScroll>
          </div>
        </article>
      </section>
      <section
        id="contact"
        className={cn(
          sections,
          styles,
          "container justify-center max-w-screen-lg"
        )}
      >
        <article className="text-center">
          <AnimationOnScroll animateIn="animate__fadeInUp">
            <h2>Contact Us</h2>
            <p>
              <a href="mailto:hello@savorbrands.com" className="underline">
                Set up an appointment
              </a>{" "}
              for a demo.
            </p>
          </AnimationOnScroll>
        </article>
      </section>
    </main>
  );
}
