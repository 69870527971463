import React from "react";
import { Nav } from "components";
import { Link } from "gatsby";
import { Squash as Hamburger } from "hamburger-react";
import Logo from "../../images/logo.svg";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { isMobile, isDesktop } from "react-device-detect";
import cn from "classnames";
import { header } from "./Header.module.scss";

export function Header({ location }) {
  return (
    <>
      <header className="lg:sticky top-0 z-50 bg-white">
        <div className="container px-2 lg:px-12">
          <div className="flex justify-between items-center">
            <Link
              to="/"
              aria-label="Homepage"
              className="block w-40 lg:w-56 text-black"
              state={{ active: "/#technology" }}
            >
              <Logo className="logo" />
            </Link>
            <Nav location={location} />
          </div>
        </div>
      </header>
    </>
  );
}
