import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Head from "components/Head";
import { Header, Footer } from "components";
import { motion, AnimatePresence } from "framer-motion";
import "./Layout.scss";

const duration = 0.2;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      type: "spring",
      mass: 0.35,
      stiffness: 75,
      duration: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

const Layout = ({
  children,
  fixed = false,
  pageTitle,
  location = location,
}) => (
  <>
    <Head pageTitle={pageTitle} />
    <Header location={location} />
    <AnimatePresence>
      <motion.div
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        <>{children}</>
      </motion.div>
    </AnimatePresence>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
