import React from "react";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import cn from "classnames";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { nav } from "./Nav.module.scss";
import { ConsoleView } from "react-device-detect";
import Scrollspy from "react-scrollspy";

export const Nav = ({ location }) => {
  const styles = "px-2 lg:px-3 py-2 uppercase hover:text-black relative";
  const [active, setActive] = React.useState(location?.state?.active);

  const onClick = (url) => {
    // setActive(url);
  };

  return (
    <>
      <nav className={cn(nav)} data-test-id="navigation">
        <Scrollspy
          items={["technology", "ar", "payments", "faqs", "contact"]}
          currentClassName="active"
          offset={-150}
          className="flex items-center lg:justify-center space-x-2 lg:space-x-4"
        >
          <AnchorLink
            to="/#technology"
            title="Technology"
            className={cn(styles)}
            stripHash
            onAnchorLinkClick={() => onClick("/#technology")}
          />
          <AnchorLink
            to="/#ar"
            title="AR"
            className={cn(styles)}
            stripHash
            onAnchorLinkClick={() => onClick("/#ar")}
          />
          <AnchorLink
            to="/#payments"
            title="Payments"
            className={cn(styles)}
            stripHash
            onAnchorLinkClick={() => onClick("/#payments")}
          />
          <Link
            to="/faqs"
            title="Faq"
            className={styles}
            activeClassName="active"
            state={{ active: "/#faqs" }}
          >
            Faq
          </Link>
          <AnchorLink
            to="/#contact"
            title="Contact"
            className={cn(styles)}
            stripHash
            onAnchorLinkClick={() => onClick("/#contact")}
          />
        </Scrollspy>
      </nav>
    </>
  );
};
